import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

const card = (
  <React.Fragment>
    <CardContent>
    <Typography variant="h5" component="div"  sx={{ fontSize: 14, color:"#fff"  }} gutterBottom>
        WITOSA POINT
      </Typography>
      <Typography variant="body2" sx={{color:"#fff"  }}>
        ul. Ludwika Idzikowskiego 16
      </Typography>
      <Typography variant="body2" sx={{  color:"#fff"  }}>
        00-710 Warszawa
      </Typography>
      {/* <img src={img}  style={{width:250, height:'auto'}}/> */}
    </CardContent>
  </React.Fragment>
);

export default function customCard() {
  return (
    <Box sx={{ width: 275, height: 'auto'}}>
      <Card variant="outlined" sx={{  background: "#F8A03A" }}>{card}</Card>
    </Box>
  );
}

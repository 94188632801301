import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';

import logo from '../images/logo.png'


const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

const card = (
  <React.Fragment>
    <CardContent>
      <img src={logo} 
      // width={150} height={'auto'}
      />
    </CardContent>
  </React.Fragment>
);

export default function customCard() {
  return (
    <Box sx={{ width: 530, height: 'auto'}}>
      <Card variant="outlined">{card}</Card>
    </Box>
  );
}

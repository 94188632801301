
import React from 'react'
import LogoCard from './components/CustomCard3'
import FirstCard from './components/CustomCard1'
import SecondCard from './components/CustomCard2'
import FourthCard from './components/CustomCard4'
import { Grid } from '@mui/material';
import './App.css';
import image from './images/1.jpg'
import image2 from './images/2.jpg'
import image3 from './images/3.jpg'
import image4 from './images/4.jpg'
import image5 from './images/5.jpg'
import logo from './images/alupoland.png'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-NZGLJC6'
}

TagManager.initialize(tagManagerArgs)

const Images = [
  {
    src: image,
    text: '',
  },
  {
    src: image2,
    text: '',
  },
  {
    src: image3,
    text: '',
  }
];


function App() {
  return (
    <>
      <div className="background">
        <Grid pl={4}
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          spacing={1}
          sx={{ height: "100%" }}
        >
          <Grid item>
            {/* <LogoCard /> */}
            <img src={logo}  width={273} height={'auto'} style={{padding:"20px", background: '#fff', borderRadius:"4px", boxSizing:'border-box'}}/>
          </Grid>
          <Grid item>
            <FirstCard />
          </Grid>
          <Grid item>
            <SecondCard />
          </Grid>
          <Grid item>
          <FourthCard/>
        </Grid>
        </Grid>
      </div>
    </>
  );
}

export default App;
